.content {
	margin: auto;
	padding: 3rem 0;
	width: 90%;
}

.accordion {
	border-radius: 18px;
	overflow: hidden;
	box-shadow: 5px 5px 30px -5px #d3d3d3;
}
.accordion__button:before {
	display: none;
}

.accordion__button {
	font-size: 1.2rem;
	background-color: white;
	color: #444;
	cursor: pointer;
	padding: 18px;
	width: 100%;
	text-align: left;
	border: none;
}

html[lang='ar'] .accordion__button {
	text-align: right;
}

.accordion__panel {
	word-wrap: break-word;
	padding: 20px;
	animation: fadein 0.35s ease-in;
	-webkit-animation: fadein 0.35s ease-in; /* Safari, Chrome and Opera > 12.1 */
	-moz-animation: fadein 0.35s ease-in; /* Firefox < 16 */
	-ms-animation: fadein 0.35s ease-in; /* Internet Explorer */
	-o-animation: fadein 0.35s ease-in; /* Opera < 12.1 */
}

.accordion__panel p {
	font-size: 1rem !important;
}

.faqItemsFlex {
	width: 100%;
	justify-content: space-between;
	font-size: 0.9em;
}

.border-line .faqItemsFlex {
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	padding-top: 1em;
	margin-top: -1em;
}

.faqTitle {
	text-align: center;
	font-size: 2.3em;
}

.no-outline:focus {
	outline: none !important;
}

.fap-arrows {
	width: 1em;
	height: 1em;
}

.fap-arrows.reversed {
	transform: rotate(180deg);
}

.help-center-wrapper {
	margin: 2em auto 5em auto;
	.help-center-title {
		font-size: 2em;
		text-align: center;
		margin: auto 1em;
		color: #000;
	}
	.default-btn {
		padding: 1em 2.5em;
		margin-top: 1.5em;
		font-weight: normal;
	}
}

.accordion__item + .accordion__item {
	border: none;
}

.plus {
	display: flex;
	width: 11px;
	height: 11px;
	background: linear-gradient(#fff, #fff), linear-gradient(#fff, #fff), #000;
	background-position: center;
	background-size: 100% 1px, 1px 100%; /*thickness = 2px, length = 50% (25px)*/
	background-repeat: no-repeat;
	margin-left: 1em;
}

.alt {
	background: linear-gradient(#000, #000), linear-gradient(#000, #000);
	background-position: center;
	background-size: 100% 1px, 1px 100%; /*thickness = 2px, length = 50% (25px)*/
	background-repeat: no-repeat;
	&.expanded {
		background-size: 100% 1px, 0px 100% !important;
	}
}

.accordion__panel p {
	margin: 0;
	padding: 0;
}

.accordion__button:hover {
	background-color: white;
}

@media (min-width: 678px) {
	.px-2-lg {
		padding: 0 2rem;
	}
	.content {
		width: 60%;
	}
}

html[lang='ar'] .plus {
	margin-left: 0;
	margin-right: 1em;
}

.dark_mode {
	.faqTitle {
		color: #fff !important;
	}
	.help-center-title {
		color: #fff;
	}
	.accordion {
		box-shadow: none;
	}
	.accordion__button {
		background: #2b2d2f;
		color: #fff !important;
	}
	.alt {
		background: linear-gradient(#fff, #fff), linear-gradient(#fff, #fff);
		background-position: 50%;
		background-repeat: no-repeat;
		background-size: 100% 1px,1px 100%;
	}
}
