.plus-main-header {
	color: black;
	margin: 3em auto 2em auto;
	@media (max-width: 768px) {
		margin: 2rem auto 1rem;
	}
	.plus-header-title-logo {
		position: relative;
		display: block;
		width: 13rem;
	}
	.plus-header-subtitle {
		padding: 1em 0;
		font-size: 1.1em;
		@media (max-width: 768px) {
			font-size: 1.2rem;
			max-width: 80%;
			text-align: center;
		}
	}
}

html[lang='ar'] .plus-header-title-logo {
	margin: 1em auto 0 auto;
}

.plus-main-body {
	width: 100%;
	.plus-sections-wrapper {
		box-shadow: 0 5px 20px -4px #d3d3d3;
		background-color: #f2f5f7;
		border-radius: 2em;
		font-size: 1.2em;
		width: -webkit-fit-content;
		width: -moz-fit-content;
		width: fit-content;
		margin: auto;
		margin-bottom: 2em;
		max-width: calc(100% - 2em);
		cursor: pointer;
		@media (max-width: 768px) {
			margin-bottom: 1rem;
		}
		&.custom_section {
			margin-bottom: 1rem !important;
		}
		.plus-section {
			padding: 0.7em 2em;
			color: #9e9e9e;
			text-align: center;
			&.selected {
				background-color: #fff;
				border: 1px solid #e5ebed;
				border-radius: 2em;
				font-weight: 600;
				color: #6c45d5;
			}
		}
	}
	.custom .cancel-disclaimer,
	.terms {
		margin: 1rem 0 !important;
	}
	.cancel-disclaimer,
	.terms {
		margin: 2em 0;
	}
	.terms {
		color: black;
		font-weight: 600;
		text-decoration: underline;
		cursor: pointer;
		padding: 0 0.5em;
		@media (max-width: 768px) {
			margin: 0;
		}
	}
}

.cards-main-wrapper {
	max-width: 90%;
	margin: auto;
	position: relative;
	color: black;
	@media (max-width: 768px) {
		flex-direction: column;
	}
}

.disclaimer-wrapper {
	@media (max-width: 768px) {
		flex-direction: column !important;
		flex-wrap: wrap;
		max-width: 85%;
		margin: auto;
		text-align: center;
	}
}

.disclaimer-wrapper:not(.custom) {
	@media (max-width: 768px) {
		padding-bottom: 1rem;
	}
}

.cancel-disclaimer {
	@media (max-width: 768px) {
		margin-bottom: 0.5em !important;
	}
}

.main-plus-wrapper {
	position: relative;
}

.main-plus-loader {
	height: 50vh;
	img {
		max-width: 7em;
	}
}

.partners-wrapper {
	@media (max-width: 768px) {
		max-width: 90%;
	}
}

.main-error {
	font-size: 1.7em;
	font-weight: 600;
	color: black;
	border: 1px solid #e8eaed;
	padding: 0.5em 1em;
	border-radius: 0.3em;
}

.mobile-plan-wrapper {
	@media (max-width: 768px) {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}

.vouchers_card {
	background-color: #fcfcfc;
	border: 1px solid #e5e7ea;
	border-radius: 0.75em;
	margin: 1em;
	padding: 2rem;
	width: 25em;
	min-width: 25rem;
	min-height: 40rem;
	@media (max-width: 768px) {
		min-height: 30rem;
	}
	.vouchers_title {
		font-size: 1.5rem;
		font-weight: 500;
		text-transform: uppercase;
		text-align: center;
		color: #000;
	}
	.vouchers_subtitle {
		font-size: 1.3rem;
		font-weight: 600;
		color: #00d1e8;
		text-align: center;
		padding: 2rem 1rem 1rem;
	}
	.vouchers_notice_wrapper {
		margin: 1rem auto;
		@media (max-width: 768px) {
			margin: 0.5rem auto;
		}
	}
	.vouchers_notice_img {
		width: 2.3rem;
	}
	.vouchers_notice_desc {
		padding: 0 1rem;
	}
	.partner_box {
		margin: 1rem 0.25rem;
	}
	.partners_wrapper {
		@media (max-width: 768px) {
			margin-top: 1rem;
		}
		.partner_img {
			width: 4.5rem;
			margin: 0 0.25rem;
			border-radius: 0.5rem;
		}
	}
	.vouchers_link_wrap {
		margin-top: 0.5rem;
		cursor: pointer;
		min-height: 1rem;
	}
	.vouchers_link {
		padding: 0 0.25rem;
		font-size: 0.65rem;
	}
}

.redeem_cta_voucher_wrapper {
	display: flex;
	align-items: flex-start;
	max-width: 23rem;
	padding-bottom: 0.5rem;
	flex-wrap: wrap;
	@media (max-width: 768px) {
		padding-bottom: 1.5rem;
	}
}

.redeem_cta_voucher {
	font-weight: 600;
	padding: 0 0.35rem;
	cursor: pointer;
	text-decoration: underline;
}

.dark_mode {
	background: #1b1c1d;
	.disclaimer-wrapper,
	.terms,
	.vouchers_title,
	.plus-header-subtitle,
	.redeem_cta_voucher_wrapper {
		color: #fff;
	}
	.vouchers_card {
		background: #2b2d2f;
		border-color: #404246;
		color: #fff;
	}
	.plus-sections-wrapper {
		box-shadow: none;
		background-color: #5d6064;
		.plus-section {
			color: #fff;
			border: none !important;
			&.selected {
				background-color: #35373b;
				color: #fff;
			}
		}
	}
}
