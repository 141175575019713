.mainloader {
	max-width: 5rem;
	margin: 5rem auto;
	display: block;
}

.min_height_50 {
	min-height: 50vh;
}

.full_height {
	height: 100vh;
}
