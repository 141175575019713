.plan-card {
	background-color: white;
	border: 1px solid #e5e7ea;
	border-radius: 0.75em;
	height: 44em;
	width: 20em;
	margin: 1em;
	padding: 1em;

	@media (max-width: 768px) {
		width: 100%;
		min-height: 35rem;
		height: fit-content;
		&.operator {
			min-height: fit-content;
			padding-top: 2rem;
			padding-bottom: 2rem;
		}
	}

	&.mainplan {
		color: #fff;
		.plan-duration,
		.plan-trial {
			color: #fff !important;
		}
	}

	&.sm-height {
		height: 36em;
		@media (max-width: 768px) {
			height: fit-content;
		}
	}

	&.md-height {
		height: 39em;
		@media (max-width: 768px) {
			height: fit-content;
		}
	}

	.plan-logo {
		img {
			max-width: 4rem;
		}

		&.rectangle {
			img {
				max-width: 9em;
				margin-top: 1em;
			}
		}

		@media (max-width: 768px) {
			height: fit-content;
		}
	}

	.plan-title {
		text-transform: uppercase;
		font-size: 1.5rem;
		font-weight: 500;
		height: 3.5em;
		margin-top: 1em;
		text-align: center;
		@media (max-width: 768px) {
			height: fit-content;
			font-size: 1.7rem;
		}
	}

	.plan-price {
		font-size: 2.3em;
		text-transform: uppercase;
		font-weight: 600;
	}

	.plan-currency {
		font-size: 1.5em;
	}

	.plan-duration {
		font-size: 1.2em;
		color: #a1a5ac;
		padding: 0 0.25em;
		@media (max-width: 768px) {
			font-size: 1.3rem;
		}
	}

	.plan-benefits {
		height: 8em;
		max-width: 85%;
		margin: 1em auto;

		@media (max-width: 768px) {
			height: unset;
			font-size: 1.2rem;
		}

		.plan-benefit {
			text-align: center;
		}
	}

	.relplans-wrapper {
		width: 100%;
		height: 14em;

		&.nomultiplier {
			height: 10em;
		}

		@media (max-width: 768px) {
			height: fit-content !important;
		}

		.description {
			max-width: 15em;
			font-size: 1.1rem;
			@media (max-width: 768px) {
				font-size: 1.2rem;
			}
		}
	}
}

.relatedplan-wrap {
	background-color: #f2f3f5;
	padding: 0.75em;
	margin: 0.75em auto;
	border-radius: 0.5em;
	cursor: pointer;

	.relplan-multiplier,
	.relplan-pricing {
		font-size: 0.9em;
	}

	.relplan-pricing {
		color: black;
		font-weight: 600;
	}

	.relplan-multiplier {
		color: #a1a5ac;
		text-decoration: line-through;
	}

	.relplan-title {
		max-width: 7em;
		text-align: center;
		color: #000 !important;
	}
	.relplan-title,
	.relplan-multiplier,
	.relplan-pricing {
		@media (max-width: 768px) {
			font-size: 1.2rem;
		}
	}
	.relplan-multiplier,
	.relplan-pricing {
		padding: 0 1rem;
	}
}

.btn-wrapper {
	width: 100%;

	button {
		width: 100%;
		font-size: 1.1rem;
		@media (max-width: 768px) {
			padding: 1.1rem 2rem !important;
			font-size: 1.3rem;
		}
	}
}

.plan-trial {
	font-size: 1rem;
	color: #777a7e;
	@media (max-width: 768px) {
		font-size: 1.1rem;
		padding-bottom: 0.5rem;
	}
}

.relplan-arrow img {
	mix-blend-mode: difference;
	max-width: 0.35em;
	height: 1em;
}

html[lang='ar'] .relplan-arrow img {
	transform: rotate(180deg);
}

.price-wrap {
	line-height: 1.35em;
}

.dark_mode {
	background: #2b2d2f;
	border-color: #404246;
	color: #fff;
	.relatedplan-wrap {
		background: #35373b;
		border: 1px solid #5d6064;
	}
	.relplan-title,
	.relplan-pricing {
		color: white !important;
	}
}
