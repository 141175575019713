.gift-wrap {
	background-color: #f2f3f5;
	max-width: 45em;
	border-radius: 9px;
	margin: 0 auto;
	padding: 1em 0;
}

.gift-btn {
	padding: 1em 1.5em !important;
	margin: 1.5em 0;
	font-size: 0.9em;
}

.redeem-btn {
	margin-left: 1em;
}

.gift {
	margin: 1em 2em -4em -3em;
	max-width: 14rem;
	width: 14rem;
	height: 14rem;
	@media (max-width: 768px) {
		height: 10rem;
	}
}

.gift-title {
	font-size: 1.2em;
	font-weight: 600;
	color: black;
	padding: 0.5em 0;
}

.gift-subtitle {
	font-size: 1em;
	line-height: 1.3em;
	color: #0000004a;
	max-width: 30em;
	padding-right: 0.5em;
}

.giftbox_wrap_img {
	position: relative;
	display: block;
	width: 20rem;
	@media (max-width: 768px) {
		margin-top: -6rem;
	}
}

html[lang='ar'] .redeem-btn {
	margin-left: 0;
	margin-right: 1em;
}

html[lang='ar'] .gift-wrap {
	padding-right: 1.5em;
	flex-direction: row-reverse;
}

html[lang='ar'] .gift-subtitle {
	padding-right: 0 !important;
	padding-left: 0.5rem;
}

@media (max-width: 768px) {
	.button-wrap {
		flex-direction: column;
		align-items: flex-start;
	}
	.redeem-btn {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.gift-btn {
		width: 100%;
		margin: 0.25em auto 1em auto;
	}
	.gift-wrap {
		flex-direction: column !important;
		width: 90%;
		max-width: 90%;
		margin: 5rem auto 2rem auto !important;
	}
	.gift {
		margin: -5em auto 0 auto;
		max-width: 10em;
		width: 10rem;
	}
	.button-wrap {
		margin-top: 1em;
	}
	.gift-spacing-mobile {
		padding: 1em;
		padding-bottom: 0;
	}
}

.redeem-link {
	color: black;
	font-size: 1.1rem;
	font-weight: 500;
	text-decoration: underline;
	cursor: pointer;
	padding-top: 1em;
}

.darkMode {
	&.gift-wrap {
		background: #2b2d2f;
		border: 1px solid #313336;
		box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
		.gift-title,
		.gift-subtitle {
			color: white;
		}
	}
	&.redeem-link {
		color: white;
	}
}
