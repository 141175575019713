.title {
	font-size: 2.3em;
	font-weight: 600;
	text-align: center;
	text-transform: capitalize;
	color: #000;
}

.img_wrapper {
	width: 4rem;
	height: 4rem;
	border-radius: 1rem;
}

.subtitle {
	font-size: 1.3rem;
	font-weight: 600;
	color: black;
}

.body_wrapper {
	margin-top: 0.6rem;
	flex-wrap: wrap;
	justify-content: center;
	align-items: flex-start;
	&.rounded,
	&.square {
		width: 100%;
		.benefit_wrapper {
			flex-direction: column;
			padding: 1rem 2.5rem;
			@media (max-width: 768px) {
				padding: 1rem;
				min-width: 11rem;
			}
			.label {
				text-align: center;
				padding-top: 1rem;
				max-width: 10rem;
				font-size: 1rem !important;
				font-weight: 500;
				@media (max-width: 768px) {
					font-size: 1.1rem !important;
				}
			}
		}
	}

	&.rounded {
		.img_wrapper {
			border-radius: 50%;
			width: 5rem;
			height: 5rem;
		}
	}

	&.square {
		.img_wrapper {
			border-radius: 1rem;
			width: 4.5rem;
			height: 4.5rem;
		}
	}

	&.box {
		flex-direction: column;
		width: fit-content;
		.desc_wrapper {
			padding: 0 1rem;
			display: flexbox;
			flex-direction: column;
			align-items: flex-start;
			@media (max-width: 768px) {
				padding: 1rem 0 !important;
				text-align: center;
			}
		}
		.benefit_wrapper {
			border: 1px solid #e8eaed;
			border-radius: 1rem;
			background-color: #fff;
			margin: 0.5rem auto;
			padding: 1rem 1.5rem;
			img {
				border-radius: 1rem;
				width: 4rem;
				height: 4rem;
			}
			.label {
				max-width: 20rem;
			}
			.description {
				min-width: 20rem;
				max-width: 20rem;
				padding-top: 0.5rem;
				@media (max-width: 768px) {
					min-width: unset !important;
				}
			}
		}
	}

	&.individual_box {
		@media (max-width: 768px) {
			margin-top: 1rem;
		}
		.benefit_wrapper {
			position: relative;
			border: 1px solid #d0d5d9;
			border-radius: 1rem;
			width: 17rem;
			height: fit-content;
			min-height: 16.5rem;
			margin: 1rem;
			align-items: flex-start !important;
		}
		.img_wrapper {
			width: 100% !important;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
			position: relative;
			height: 7.5rem;
		}
		.desc_wrapper {
			padding: 1rem;
			@media (min-width: 769px) {
				position: absolute;
				top: 8.5rem;
			}
		}
		.label {
			font-size: 1.2rem;
			@media (max-width: 768px) {
				max-width: unset;
				font-size: 1rem;
			}
		}
		.description {
			margin-top: 0.5rem;
			max-width: 15rem;
			font-size: 0.9rem;
		}
		.individual_box_img {
			img {
				border-top-left-radius: 1rem;
				border-top-right-radius: 1rem;
			}
		}
	}
}

.generic-rounded-benefits {
	width: 98%;
	position: relative;
}

html[lang='ar'] .body_wrapper.individual_box {
	.benefit_wrapper {
		min-height: 17.5rem;
	}
	.individual_box_img {
		bottom: -3.25rem;
		@media (max-width: 768px) {
			bottom: -2.85rem;
		}
	}
}

.benefit_wrapper {
	min-width: 14rem;
	flex-wrap: wrap;
	justify-content: center;
	@media (max-width: 768px) {
		max-width: 12rem;
	}
	.label {
		font-weight: 600;
		font-size: 1.1rem;
		color: #000;
		@media (max-width: 768px) {
			max-width: 6rem;
		}
	}
}

.GenericBenefits_wrapper {
	margin: auto;

	.gifts_page_generic_benefits_title {
		color: '#000';
		font-size: 1.225rem;
		font-weight: 600;
		font-style: normal;
		font-family: 'Euclid Circular A';
		line-height: normal;
		text-align: center;
		text-transform: uppercase;
		padding-top: 0.7rem;
	}

	&.individual_box {
		align-items: center;
		.title {
			text-align: center;
			@media (max-width: 768px) {
				padding: 0;
				font-size: 1.7rem;
			}
		}
		img.logo {
			height: fit-content;
			margin: 1em 0em 0em;
			max-width: 9em;
			width: 9rem;
		}
	}
	&.wrapped_box {
		background-color: #f6f6f6;
		border: 1px solid hsla(0, 0%, 62%, 0.169);
		border-radius: 0.5em;
		width: 97%;
		max-width: 75rem;
		display: flex;
		align-items: center;
		flex-direction: column;
		padding: 1.5em 2em 3em !important;
		.body_wrapper {
			margin-top: 0rem;
		}
		.title {
			font-size: 1.4rem;
			padding: 1rem 0;
			text-transform: uppercase;
		}
		img.logo {
			height: fit-content;
			margin: 1em auto;
			max-width: 9em;
			width: 9rem;
		}
		.benefit_wrapper {
			background-color: #fff;
			border: 1px solid hsla(0, 0%, 62%, 0.169);
			border-radius: 0.5em;
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			align-items: center;
			justify-content: flex-start;
			text-align: center;
			height: 19rem;
			margin: 1rem;
			padding: 2rem 1rem;
			.desc_wrapper {
				display: flex;
				flex-direction: column;
				align-items: center;
				margin: 1.5rem auto 0rem;
				.label {
					max-width: 10rem;
				}
				.description {
					max-width: 13rem;
					padding: 0.5rem 0;
				}
			}
		}
	}
}

.dark_mode {
	.title {
		color: #fff;
	}
	.benefit_wrapper {
		background-color: #1b1c1d;
		color: #fff;
		border: 1px solid #313336 !important;
		.label {
			color: #fff;
		}
	}
	&.rounded,
	&.square {
		.benefit_wrapper {
			border-color: transparent !important;
		}
	}
	&.wrapped_box {
		background-color: #2b2d2f;
		.benefit_wrapper {
			background: #404246;
		}
	}
}
